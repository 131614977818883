import React from 'react';
import { Helmet } from "react-helmet";
import ErrorDisplay from './ErrorDisplay'
import axios from 'axios'
import ErrorMessage, { errorMessageMap } from './ErrorMessage'
interface IState {
    errorMessage: string,
    showError: boolean
}
class App extends React.Component<any, IState> {
    state = {
        errorMessage: '',
        showError: false //默认没有登陆失败哦
    }
    render = () => {
        return <>
            <Helmet>
                <title>国权路18号线穿越自动化数据采集系统</title>
            </Helmet>
            <form id="form" className="form-signin">
                <ErrorDisplay showError={this.state.showError} errorMessage={this.state.errorMessage} />
                <h4 className="h4 mb-3 font-weight-normal flex align-center">
                    <img src="/favicon.png" alt="" className="favicon" />
                    欢迎登录国权路18号线穿越 <br />
                    自动化数据采集系统
                </h4>
                <input type="text" id="user-name" className="form-control no-border name-input-border" onChange={this.clearError.bind(this)} placeholder="用户名" required autoFocus autoComplete="off" />
                <br />
                <input type="password" id="password" placeholder="密码" className="form-control no-border password-input-border" />
                <br />
                <button id="submit" className="btn btn-primary btn-block" type="submit" onClick={this.attemptLogin}>登录</button>
                <p className="mt-5 mb-3 text-muted">&copy; 上勘院量测中心研发所版权所有 2018~2019</p>
            </form>

            <form id="sso-form" style={{ display: 'none' }} method="post" action="" encType="application/x-www-form-urlencoded">
                <input id="origin" type="hidden" name="origin" />
                <input id="redirect" type="hidden" name="redirect" />
                <input id="uid" type="hidden" name="uid" />
                <input id="userName" type="hidden" name="userName" />
            </form>
        </>
    }
    clearError() {
        this.setState({ showError: false })
    }
    preCheck({ userName, password }) {
        if (userName && password) return true
        this.setState({ showError: true, errorMessage: errorMessageMap[ErrorMessage.FormNotComplete] })
        return false
    }
    attemptLogin = async (e: React.MouseEvent) => {
        e.preventDefault()
        const userName = (document.getElementById('user-name') as HTMLInputElement).value
        const password = (document.getElementById('password') as HTMLInputElement).value
        if (!this.preCheck({ userName, password })) return
        try {
            const ret = await axios.post('/login', {
                userName,
                password
            })
            onLoginSuccess(ret.data)
        } catch (error) {
            this.setState({ showError: true, errorMessage: errorMessageMap[ErrorMessage.LoginFail] })
        }
    }
}
/** sso的验证地址 */
function setSSOUrl() {
    const form = document.getElementById('sso-form') as HTMLFormElement
    if (location.origin.indexOf('https') >= 0) {
        form.setAttribute('action', '//sso.ce-safe.com/sso/redirect')
    } else {
        form.setAttribute('action', 'http://localhost:3900/sso/redirect')
    }
}
function onLoginSuccess({ userName, uid }) {
    setSSOUrl();
    (document.getElementById('origin') as HTMLInputElement).value = location.origin;
    (document.getElementById('redirect') as HTMLInputElement).value = '';
    (document.getElementById('userName') as HTMLInputElement).value = userName;
    (document.getElementById('uid') as HTMLInputElement).value = uid;
    (document.getElementById('sso-form') as HTMLFormElement).submit()
}
export default App;

